//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        propValue: {
            type: String,
            require: true,
            default: '',
        },
        backgroundImage: {
            type: String,
            require: true,
            default: '',
        },
        imageStyle: {
            type: Object,
            require: true,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: {},
        },
        element: {
            type: Object,
            default: () => {},
        },
    },

}
